import React, { Component } from "react";
import '../css/Login2.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from "axios";
import md5 from 'md5'
import logo from '../assets/img/Baka.png';
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
// const MySwal = withReactContent(Swal)


const cookies = new Cookies();
const baseUrl = "http://localhost:3001/usuarios"

var user = [{
    name: 'Jannete',
    pass: 'narom85'
},{
    name: 'Elisa',
    pass: 'Elisa1234'
},{
    name: 'Irving',
    pass: 'C@leras'
}
]

class Login extends React.Component {
    state={
        username: '',
        password: ''
    }
    // sleep(time){
    //     console.log('Timer');
    //     return new Promise((resolve)=>setTimeout(resolve,time)

    //   )
    // }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleChange= e=>{
        this.setState({username: e.target.value});
    }
    handleChangePass = e=>{
        this.setState({ password: e.target.value });
    }
    iniciarSesion= async()=>{

        var result = user.find(x => x.name === this.state.username && x.pass === this.state.password)
        
        console.log(result);
        if(result === undefined){
            console.log('Uuario y/o contraseña incorrecta');
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Usario y/o contraseña incorrecta',
                showConfirmButton: false,
                timer: 1500
              })
        }
        else{
          
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Bienvenida',
                showConfirmButton: false,
                timer: 1500
              })
              

              await this.sleep(1500);

            const resp = 
            [
                {
                    "id": 1,
                    "apellido_paterno": "Ayala",
                    "apellido_materno": "Diaz",
                    "nombre": "Elisa",
                    "username": "Elisa",
                    "password": "827ccb0eea8a706c4c34a16891f84e7b"
                }
            ]
            cookies.set('id', 1, {path: "/" });
            cookies.set('apellido_paterno', "Ayala", {path: "/" });
            cookies.set('apellido_materno', "Diaz", {path: "/" });
            cookies.set('nombre', "Elisa", {path: "/" });
            cookies.set('username', "Elisa", {path: "/" });

            window.location.href="./menu"

            return resp;
        }
    }

    componentDidMount(){
        if(cookies.get('username')){
            window.location.href="./menu";
        }
    }
    render() {
        return (
            

  <div className="grid align__item">
    <div className="register" style={{background: 'white'}}>
      <img src={logo} width="250" height="150" />
      <h3>Iniciar sesión</h3>

      <form action="" method="post" className="form" >

        <div className="form__field">
          <input type="email"  onChange={this.handleChange} placeholder="info@mailaddress.com" />
        </div>

        <div className="form__field">
          <input type="password" onChange={this.handleChangePass}  placeholder="••••••••••••" />
        </div>

        <div className="form__field">
          <input type="button" onClick={()=> this.iniciarSesion()} value="Iniciar sesión" />
        </div>

      </form>
    </div>

  </div>
        );
    }
}

export default Login;