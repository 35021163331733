import React, { useEffect, useState } from "react";
import Sidebar from './Components/Sidebar/Sidebar'
import MaterialTable from "material-table";
import 'material-icons/iconfont/material-icons.css';
import { collection, getDocs, addDoc,doc, updateDoc, deleteDoc  } from "firebase/firestore";

// import { getDatabase, ref, child, push, update } from "firebase/database";
import db from '../firebase-config'

function Clientes(){

  const [array,setArray] = useState([]);
  const [respaldo,setRespaldo] = useState([]);

   useEffect(() => {

    const obtenerDatos = async() => {
      console.log('datos');
      const datos = await getDocs(collection(db,'clientes'))
    
      datos.forEach((documento) => {       
        var objects = {
          id: documento.id,
          domicilio: documento.data().domicilio,
          estadoCivil: documento.data().estadoCivil,
          nacimiento: documento.data().nacimiento,
          nombre: documento.data().nombre,
          rfc: documento.data().rfc,
          telefono: documento.data().telefono,
        };
         
      setArray(oldArray => [...oldArray,objects] );
      //   setRespaldo(oldArrayResp => [...oldArrayResp,documento.data()] );
      });
    }
    obtenerDatos();
  }, []);
  
  
  const columnas= [
    {
      title: 'Nombre',
      field: 'nombre'
    },
    {
     title: 'Fecha de nacimiento',
     field: 'nacimiento'
   },
   {
     title: 'Domicilio',
     field: 'domicilio',
   },
   {
     title: 'RFC',
     field: 'rfc'
   },
   {
     title: 'Estado Civil',
     field: 'estadoCivil'
   },
   {
     title: 'Telefono',
     field: 'telefono'
   }
   ];
  
   function DeleteCliente(updateRows){
    console.log('Entra al delete');
    console.log(updateRows);
    console.log(updateRows.id);
    
    const DeletesCliente = async() => {
  
      try {
        await deleteDoc(doc(db, "clientes", updateRows.id));
        console.log("Document drop with ID: ", updateRows.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }     
    }
    DeletesCliente();
   }

   function writeNewPost(oldRow,updateRow) {
    
    const updateCliente = async() => {
      
      const docRef = doc(db, "clientes", oldRow.id);
      try {
        await updateDoc(docRef, {
          domicilio: updateRow.domicilio,
          estadoCivil: updateRow.estadoCivil,
          nacimiento: updateRow.nacimiento,
          nombre: updateRow.nombre,
          rfc: updateRow.rfc,
          telefono: updateRow.telefono,
        });  
        console.log("Document update with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }     
    }
    updateCliente();
  }

  
   function NuevoCliente(Cliente){
    console.log('NUEVO CLIENTE');
    console.log(Cliente);

    const SendDatos = async() => {
      try {
        const docRef = await addDoc(collection(db, "clientes"), {
          domicilio: Cliente.domicilio,
          estadoCivil: Cliente.estadoCivil,
          nacimiento: Cliente.nacimiento,
          nombre: Cliente.nombre,
          rfc: Cliente.rfc,
          telefono: Cliente.telefono
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }      
    }
    SendDatos();
   }
   //console.log(respaldo);
    return (
      <div className="wrapper">
      <nav>
        <Sidebar></Sidebar>
      </nav> 
      <div className="marg">
      <h1>Administrar clientes</h1>
        <MaterialTable 
          columns={columnas}
          data={array}
          title='Clientes'
          editable={{
            onRowAdd:(newRow)=> new Promise((resolve, reject) =>{
              const updateRows =[...array, newRow]
              setArray(updateRows)
              resolve()
              NuevoCliente(newRow);
              console.log(newRow)
            }),
            onRowDelete:selectedRow => new Promise((resolve, reject) =>{
              const index = selectedRow.tableData.id;
              
              DeleteCliente(selectedRow)
              const updateRows = [...array]
              updateRows.splice(index,1)
              // setData(updateRows)
              
              resolve()
            }),
            onRowUpdate:(updateRow, oldRow)=> new Promise((resolve, reject)=> {
              const index= oldRow.tableData.id;
              const updateRows=[...array]
              updateRows[index]=updateRow
              setArray(updateRows)
              writeNewPost(oldRow,updateRow)
              resolve()
              
              // ActualizarFirebase(oldRow,updateRow)
            })
          }}  
          options={{
            actionsColumnIndex: -1,
            exportAllData: true,
            columnsButton: true, 
            exportCsv: true, 
            exportButton: true
          }}
          localization={{
            header:{
              actions: 'Acciones'
            },
            body: { editRow: { deleteText: '¿Estás seguro de que quieres borrarlo?' } }
          }}
        />
      </div>
    </div>
      
    );
}

export default Clientes;