import React, { useEffect, useState } from "react";
import Sidebar from './Components/Sidebar/Sidebar'
import { Button, Modal } from 'react-bootstrap';
import 'material-icons/iconfont/material-icons.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import MaterialTable from "material-table";
import 'material-icons/iconfont/material-icons.css';
import db from '../firebase-config'
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";

function Pagos() {

  const [array, setArray] = useState([]);
  const [arrayPrestamos, setArrayPrestamos] = useState([]);
  const [show, setShow] = useState(false);
  const [PrestamosActivos, setPrestamosActivos] = useState([]);
  const [PrestamosSeleccionado, setPrestamosSeleccionado] = useState([]);
  const [test, setTest] = useState([]);

  useEffect(() => {

    const obtenerDatos = async () => {
      console.log('datos');
      const datos = await getDocs(collection(db, 'clientes'))

      datos.forEach((documento) => {
        var objects = {
          id: documento.id,
          domicilio: documento.data().domicilio,
          estadoCivil: documento.data().estadoCivil,
          nacimiento: documento.data().nacimiento,
          name: documento.data().nombre,
          rfc: documento.data().rfc,
          telefono: documento.data().telefono,
        };

        setArray(oldArray => [...oldArray, objects]);
        //   setRespaldo(oldArrayResp => [...oldArrayResp,documento.data()] );
      });
    }
    obtenerDatos();
  }, []);
  useEffect(() => {

    const obtenerDatosPrestamo = async () => {
      console.log('datos');
      const datos = await getDocs(collection(db, 'prestamos'))

      datos.forEach((documento) => {
        var obj = {
          id: documento.id,
          Pagos: documento.data().Pagos,
          PagosStatus: documento.data().PagosStatus,
          activo: documento.data().activo,
          cantidadPrestada: documento.data().cantidadPrestada,
          cantidadinteres: documento.data().cantidadinteres,
          name: documento.data().deudor,
          fechaContrato: documento.data().fechaContrato,
          fechaLiquidacion: documento.data().fechaLiquidacion,
        };
        setArrayPrestamos(oldArray => [...oldArray, obj]);

      });
    }
    obtenerDatosPrestamo();
  }, []);
  const columnas = [
    {
      title: 'name',
      field: 'name'
    },
    {
      title: 'cantidadPrestada',
      field: 'cantidadPrestada'
    },
    {
      title: 'fechaContrato',
      field: 'fechaContrato'
    },
    {
      title: 'fechaLiquidacion',
      field: 'fechaLiquidacion',
    },


  ];
  const handleOnSearch = (string, results) => {

    console.log(string, results)
  }
  const handleOnSelect = (item) => {
    console.log('FOUND:');
    console.log(item)
    setPrestamosActivos(arrayPrestamos.filter((element) => element.name == item.name));

  }
  const clickView = (rowData) => {
    console.log('Click perro');
    setShow(true)
    setPrestamosSeleccionado(rowData)



    var objects = {
      Pagos: rowData.Pagos,
      PagosStatus: rowData.PagosStatus
    };

    setTest(oldArray => [...oldArray,objects] );


  }
  const Pagar = () =>{
    console.log('Generando pago');
  }


  return (
    <div>
      <div className="wrapper">
        <nav>
          <Sidebar></Sidebar>
        </nav>
        <div className="marg">
          <div id="collum">
            <div className="blocks">
              <div>
                <div style={{ width: 900 }}>
                  <div>
                    Busqueda por cliente
                    <ReactSearchAutocomplete
                      items={array}
                      onSearch={handleOnSearch}
                      onSelect={handleOnSelect}
                      styling={
                        {
                          color: "black",
                          lineColor: "rgb(255, 23, 23)",
                          backgroundColor: "white",
                          hoverBackgroundColor: "1342DF",
                          // boxShadow: "rgba(63, 74, 191, 0.6) 0px 1px 6px 0px",
                        }
                      }
                    />
                    <h6>Prestamos encontrados</h6>
                    {PrestamosActivos.length}

                    <MaterialTable
                      columns={columnas}
                      data={PrestamosActivos}
                      title='Clientes'
                      actions={[
                        {
                          icon: "preview",
                          tooltip: "Save User",
                          onClick: (event, rowData) => {
                            clickView(rowData)
                          }
                          // alert("You saved " + rowData.name)
                        }
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        exportAllData: true,
                        columnsButton: true,
                        exportCsv: true,
                        exportButton: true
                      }}
                      localization={{
                        header: {
                          actions: 'Acciones'
                        },
                        body: { editRow: { deleteText: '¿Estás seguro de que quieres borrarlo?' } }
                      }}
                    />


                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Modal size="lg" show={show} onHide={() => setShow(true)}>
          <Modal.Header closeButton>
            <Modal.Title>Datos del préstamo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group form-inline">
              <label>Cliente</label>
              <br />
              <label>{PrestamosSeleccionado.name}</label>
              <br />
              <br />
              {test.map(function(data) {
                // console.log('Log en map');
                // console.log(data);
                return (
                  <div>
                    <label>Fechas de pagos</label>
                    <table>
                    <tr>
                      <th>{data.Pagos[0]}</th>
                      <th>{data.Pagos[1]}</th>
                      <th>{data.Pagos[2]}</th>
                      <th>{data.Pagos[3]}</th>
                      <th>{data.Pagos[4]}</th>
                      <th>{data.Pagos[5]}</th>
                    </tr>
                    <tr>
                      <td>{data.PagosStatus[0] ? <Button variant="success">Pagado</Button> : <Button variant="danger" onClick={() => Pagar()}>Sin Pagar</Button>}</td>
                      <td>{data.PagosStatus[1] ? <Button variant="success">Pagado</Button> : <Button variant="danger">Sin Pagar</Button>}</td> 
                      <td>{data.PagosStatus[2] ? <Button variant="success">Pagado</Button> : <Button variant="danger">Sin Pagar</Button>}</td>
                      <td>{data.PagosStatus[3] ? <Button variant="success">Pagado</Button> : <Button variant="danger">Sin Pagar</Button>}</td>
                      <td>{data.PagosStatus[4] ? <Button variant="success">Pagado</Button> : <Button variant="danger">Sin Pagar</Button>}</td>
                      <td>{data.PagosStatus[5] ? <Button variant="success">Pagado</Button> : <Button variant="danger">Sin Pagar</Button>}</td>
                    </tr>
                    </table>
                  </div>
                )
              })}
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setShow(false)
              // setAbrir(false)
              // setCantidad('')
            }

            } >
              Close
            </Button>
            {/* <h2>Holi</h2> */}
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  )
}

export default Pagos;