import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
// import numeros_a_letras from "numeros_a_letras";
import numeros from "numeros_a_palabras";
import Print from './print';

const Preview = (props) => {

  const [editable, setEditable] = useState(false);
  const [show, setShow] = useState(false);
  const [cantidad, setCantidad] = useState(0);
  const [cantidadLetra, setCantidadLetra] = useState('');
  const [cifra, setCifra] = useState(0);
  const [cantidadLetradividida, setCantidadLetradividida] = useState('');

  const [abrir, setAbrir] = useState(false);

  var curr = new Date();
  curr.setDate(curr.getDate());
  // var date = curr.toISOString().substring(0,10);

  const [date, setdate] = useState(curr.toISOString().substring(0,10));


  useEffect(() => {

    const obtenerDatos = async() => {
      setCantidad(parseInt(props.cantidad).toLocaleString())
      setCantidadLetra(props.cantidadLetra)
      setCifra(parseInt(props.cantidad * .03).toLocaleString())
      
      numeros.numero2word().Config._setSingular("PESO");
      numeros.numero2word().Config._setPlural("PESOS");


          
    // const letra = numeros.numero2word(numero).toString().replace("MIL", ' MIL ').toUpperCase()
    // var nueva = letra.replace('MIL LONES', 'MILLONES')
    // var nueva2 = nueva.replace('MIL LON', 'MILLON')

    const letra = numeros.numero2word(parseInt(props.cantidad * .03), 'entero', 2).toString().replace("MIL", ' MIL ').toUpperCase()
    var nueva = letra.replace('MIL LONES', 'MILLONES')
    var nueva2 = nueva.replace('MIL LON', 'MILLON')

      setCantidadLetradividida(nueva2)

      setAbrir(false)
    }
    obtenerDatos();
  }, []);

  function handleCantidad(e) {
    console.log(e.target.value);
    setCantidad(e.target.value);
  }
  function handleCantidadLetra(e) {
    setCantidadLetra(e.target.value);
  }
  function handleCantidadInteres(e) {
    setCifra(e.target.value);
  }
  function handleTextoInteres(e) {
    setCantidadLetradividida(e.target.value);
  }
  function handleDateChange(e) {
    setdate(e.target.value);
  }
  

  if (abrir == false) {
    return (<Button className="buttonGenerar" variant="primary" onClick={() =>
      {
      setShow(true)
      setAbrir(true)
      }
      }>Generar</Button>
    )
  }
  else{
    if(cantidad === 'NaN')
    {
      setCantidad(parseInt(props.cantidad).toLocaleString())
      setCantidadLetra(props.cantidadLetra)
      setCifra(parseInt(props.cantidad * .03).toLocaleString())
      
      numeros.numero2word().Config._setSingular("PESO");
      numeros.numero2word().Config._setPlural("PESOS");
      
       const letra = numeros.numero2word(parseInt(props.cantidad * .03), 'entero', 2).toString().replace("MIL", ' MIL ').toUpperCase()
        var nueva = letra.replace('MIL LONES', 'MILLONES')
        var nueva2 = nueva.replace('MIL LON', 'MILLON')

      setCantidadLetradividida(nueva2)
    }
    if(cantidad === '')
    {
      setCantidad(parseInt(props.cantidad).toLocaleString())
      setCantidadLetra(props.cantidadLetra)
      setCifra(parseInt(props.cantidad * .03).toLocaleString())
      numeros.numero2word().Config._setSingular("PESO");
      numeros.numero2word().Config._setPlural("PESOS");

      const letra = numeros.numero2word(parseInt(props.cantidad * .03), 'entero', 2).toString().replace("MIL", ' MIL ').toUpperCase()
      var nueva = letra.replace('MIL LONES', 'MILLONES')
      var nueva2 = nueva.replace('MIL LON', 'MILLON')

      setCantidadLetradividida(nueva2)
    }
    
    console.log('pruebas');
    console.log(cantidad);
  
  return (
    <div className="wrapper">
      <div className="marg">
        <div>
          
          <Modal show={show} onHide={() => setShow(true)}>
            <Modal.Header closeButton>
              <Modal.Title>¿Datos correctos?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group form-inline">
                <label>Cliente</label>
                <input type="text" className="form-control" placeholder={props.cliente.name} readOnly />
                <label>Cantidad a prestar</label>
                <input type="text" className="form-control" placeholder={cantidad} readOnly={true} onChange={handleCantidad} value={cantidad} />
                <label>Texto de cantidad a prestar</label>
                <input type="text" className="form-control" placeholder={cantidadLetra} readOnly={editable} onChange={handleCantidadLetra} value={cantidadLetra} />
                <label>Cantidad de intereses</label>
                <input type="text" className="form-control" placeholder={cifra} readOnly={editable}  onChange={handleCantidadInteres} value={cifra} />
                <label>Texto de intereses</label>
                <input type="text" className="form-control" placeholder={cantidadLetradividida} readOnly={editable} onChange={handleTextoInteres} value={cantidadLetradividida}/>
                <label>Fecha del préstamo</label>
                <input type="date" className="form-control" defaultValue={date} onChange={handleDateChange} />
              </div>
             
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() =>{
                setShow(false)
                setAbrir(false)
                setCantidad('')
              }
                
              } >
                Close
              </Button>
              <Print cliente={props.cliente} 
              cantidad={cantidad} 
              cantidadLetra={cantidadLetra} 
              cantidadPagares={props.cantidadPagares}   
              avalDomicilio1 = {props.avalDomicilio1}
              avalDomicilio2 = {props.avalDomicilio2}
              avalNombre1 = {props.avalNombre1}
              avalNombre2 = {props.avalNombre2}
              avales = {props.avales}
              fechaPrestamo = {date}
/>
            </Modal.Footer>
            
          </Modal>

        </div> 
      </div>
    </div>
  );
  }

}


export default Preview;

// SHIFT + ALT + F 