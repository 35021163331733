import React, { useState } from "react";
import numeros from "numeros_a_palabras";

import Preview from './imprimir/preview';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { InputGroup, FormControl,Card } from 'react-bootstrap';
import db from '../firebase-config'
import { collection, getDocs } from "firebase/firestore";
import './configuraciones/css/geo.css'

class NuevoPrestamo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'Selecciona al cliente',
      cantidad: '',
      cantidadLetra: '',
      cantidadPagares: 6,
      array: [],
      show: true,
      inputAvals: true,

      avalNombre1: '',
      avalDomicilio1: '',
      avalNombre2: '',
      avalDomicilio2: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.cantidadChange = this.cantidadChange.bind(this);
    this.cantidadPagaresChange = this.cantidadPagaresChange.bind(this);

    this.checkAvales = this.checkAvales.bind(this)


    this.changeNombreAval1 = this.changeNombreAval1.bind(this)
    this.changeDomicilioAval1 = this.changeDomicilioAval1.bind(this)
    this.changeNombreAval2 = this.changeNombreAval2.bind(this)
    this.changeDomicilioAval2 = this.changeDomicilioAval2.bind(this)
    
    
  }
  async componentDidMount() {
    var data = [];
    var contador = 0;
    const datos = await getDocs(collection(db, 'clientes'))
    datos.forEach((documento) => {
      const clientedata = documento.data();
      console.log(clientedata);

      const obj = {
        id: contador,
        name: clientedata.nombre,
        tel: clientedata.telefono,
        direccion: clientedata.domicilio,
        colonia: clientedata.colonia
      }

      data.push(obj);
      contador = contador + 1;
    })
    this.setState({ array: data })
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  // replacemiles(valor){
  //   var correccion = valor
  //   if(valor.includes('MIL'))
  //   {
  //     correccion = valor.replace("MIL", ' MIL ')
  //   }
  //   // if(valor.includes('DIEZMIL'))
  //   // {
  //   //    correccion = valor.replace("DIEZMIL", 'DIEZ MIL')
  //   // }
  //   // else if(valor.includes('DOSMIL'))
  //   // {
  //   //   correccion =  valor.replace("DOSMIL", 'DOS MIL')
  //   // }
  //   // else if(valor.includes('TREINTAMIL'))
  //   // {
  //   //   correccion =  valor.replace("TREINTAMIL", 'TREINTA MIL')
  //   // }
  //   // else if(valor.includes('CUATROMIL'))
  //   // {
  //   //   correccion =  valor.replace("CUATROMIL", 'CUATRO MIL')
  //   // }
  //   // else if(valor.includes('CINCOMIL'))
  //   // {
  //   //   correccion =  valor.replace("CINCOMIL", 'CINCO MIL')
  //   // }
  //   // else if(valor.includes('SEISMIL'))
  //   // {
  //   //   correccion =  valor.replace("SEISMIL", 'SEIS MIL')
  //   // }
  //   // else if(valor.includes('SIETEMIL'))
  //   // {
  //   //   correccion =  valor.replace("SIETEMIL", 'SIETE MIL')
  //   // }
  //   // else if(valor.includes('OCHOMIL'))
  //   // {
  //   //   correccion =  valor.replace("OCHOMIL", 'OCHO MIL')
  //   // }
  //   // else if(valor.includes('NUEVEMIL'))
  //   // {
  //   //   correccion =  valor.replace("NUEVEMIL", 'NUEVE MIL')
  //   // }
        
  //   return correccion;
  // }

  cantidadChange(event) {
    // console.log('VALOR CHANGE:');
    // console.log(event.target.value);
    let numero = 0
    numero = event.target.value
  
    numeros.numero2word().Config._setSingular("PESO");
    numeros.numero2word().Config._setPlural("PESOS");

    
    const letra = numeros.numero2word(numero).toString().replace("MIL", ' MIL ').toString().toUpperCase()
    var nueva = letra.replace('MIL LONES', 'MILLONES')
    var nueva2 = nueva.replace('MIL LON', 'MILLON')

    this.setState({ cantidadLetra: nueva2 });
    this.setState({ cantidad: event.target.value });
    
  }
  cantidadPagaresChange(event) {
    console.log(event.target.value);
    this.setState({ cantidadPagares: event.target.value });
  }
  Preview() {
    console.log('PREVIEW');
    this.setState({show: false})
  }

  checkAvales(){
    console.log('Check');
      
    if(this.state.inputAvals)
    {
      this.setState({inputAvals: false})
    }else
    {
      this.setState({inputAvals: true})
    }
  }

  //Avales
  changeNombreAval1(event){
    console.log(event.target.value);
    this.setState({avalNombre1: event.target.value})
  }
  changeDomicilioAval1(event){
    console.log(event.target.value);
    this.setState({avalDomicilio1: event.target.value})
  }
  changeNombreAval2(event){
    console.log(event.target.value);
    this.setState({avalNombre2: event.target.value})
  }
  changeDomicilioAval2(event){
    console.log(event.target.value);
    this.setState({avalDomicilio2: event.target.value})
  }


  render() {
    const handleOnSearch = (string, results) => {
      console.log(string, results)
    }
    const handleOnHover = (result) => {
      console.log(result)
    }
    const handleOnSelect = (item) => {
      this.setState({ value: item })
    }
    const handleOnFocus = () => {
      console.log('Focused')
    }
    const formatResult = (item) => {
      return (
        <div className="SearchUser">
          <span handleOnSelect id="span1" style={{ display: 'block', textAlign: 'left', color: 'black' }}>Nombre: {item.name}</span>
          {/* <span style={{ display: 'block', textAlign: 'left' }}>{item.nombre}</span> */}
        </div>
      )
    }

    if (this.state.array.length === 0)
      return (
        <h1>render</h1>
      )
    else {
      return (
        <div className="wrapper">
          <div className="marg">
          <div id="collum">
            <div className="blocks">
              <div>
                <header className="App-header">
                  <div style={{ width: 400 }}>
                    <ReactSearchAutocomplete
                      items={this.state.array}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      styling={
                        {
                          color: "black",
                          lineColor: "rgb(255, 23, 23)",
                          // backgroundColor: "Green"
                          backgroundColor: "white",
                          hoverBackgroundColor: "1342DF",
                          // boxShadow: "rgba(63, 74, 191, 0.6) 0px 1px 6px 0px",
                        }
                      }
                    />
                  </div>
                  <br />
                  <div id="checkAval" class="form-check" >
                <input class="form-check-input" type="checkbox" value="" onClick={this.checkAvales} id="flexCheckDefault" />
                <label  class="form-check-label">
                  Agregar Avales
                </label>
              </div>
                </header>
                <br />
                <h6>Total del préstamo</h6>
                <div style={{ display: 'flex' }}>
                  <InputGroup.Text>$</InputGroup.Text>
                  <FormControl type="number" onChange={this.cantidadChange} />
                  <InputGroup.Text>0.00</InputGroup.Text>
                  {/* <input type="number" onChange={this.cantidadChange}></input> */}
                </div>
                <br />
              </div>
              <h6>${this.state.cantidadLetra}</h6>
              <label>
                <h6>Cantidad de pagares</h6>
                <select className="form-select form-select-sm" class="form-control" aria-label=".form-select-sm example" onChange={this.cantidadPagaresChange}>
                  <option selected>Pagares</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6" selected>6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </label>
                <Preview 
                  cliente={this.state.value} 
                  cantidad={this.state.cantidad} 
                  cantidadLetra={this.state.cantidadLetra} 
                  cantidadPagares={this.state.cantidadPagares} 
                  abrir={true}
                  avalNombre1={this.state.avalNombre1}
                  avalDomicilio1={this.state.avalDomicilio1}
                  avalNombre2={this.state.avalNombre2}
                  avalDomicilio2={this.state.avalDomicilio2}
                  avales={this.state.inputAvals}
                />
            </div>
            <div className="blocks">
              <header className="prueba">
            
              <div hidden={this.state.inputAvals} className="inputAvales">
              <p>Aval 1</p>
                <InputGroup className="mb-3">
                  <InputGroup.Text >Nombre</InputGroup.Text>
                  <FormControl aria-label="Nombre" onChange={this.changeNombreAval1}/>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Domicilio</InputGroup.Text>
                  <FormControl aria-label="Domicilio" onChange={this.changeDomicilioAval1}/>
                </InputGroup>
                <p>Aval 2</p>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Nombre</InputGroup.Text>
                  <FormControl aria-label="Nombre2" onChange={this.changeNombreAval2}/>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Domicilio</InputGroup.Text>
                  <FormControl aria-label="Domicilio2" onChange={this.changeDomicilioAval2}/>
                </InputGroup>
                </div>
              </header>
            </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default NuevoPrestamo;