import React, { useState } from 'react';
import { jsPDF } from "jspdf";
import numeros from "numeros_a_palabras";
import Preview from './preview';
import { collection, getDocs, addDoc } from "firebase/firestore";
import db from '../../firebase-config'
import moment from 'moment';

class Print extends React.Component {
  
  NuevoPrestamo(cliente, cantidad, fechapagos, fechavencimiento, cantidadinteres){
    console.log('NUEVO PRESTAMO');
    var dateString = this.props.fechaPrestamo;
    var status = [] 
    fechapagos.forEach(element => {
      status.push(false);
    });

    const SendDatos = async() => {
      try {
        const docRef = await addDoc(collection(db, "prestamos"), {
          Pagos: fechapagos,
          PagosStatus: status,
          activo: true,
          cantidadPrestada: cantidad,
          deudor: cliente.name,
          fechaContrato: dateString,
          fechaLiquidacion: fechavencimiento,
          interes: 3,
          cantidadinteres: cantidadinteres
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }      
    }
    SendDatos();
  }

  obtenerFecha(){

    const month = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];

    //const d = new Date();
    // let mes = month[d.getMonth()];
    // let day = d.getDate();
    // let year = d.getFullYear();

    var array = this.props.fechaPrestamo.split('-')
    let day = array[2];
     let mes =  month[parseInt(array[1]-1)];
     let year = array[0];


    console.log(day);
    return day +' '+mes +' DEL '+ year;
  }
  isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  obtenerPagos(cantidadPagares){
    console.log('Obtener pagos');

    var d2 = new Date();
    
    var array = this.props.fechaPrestamo.split('-')
    d2.setDate(array[2])
    d2.setMonth(array[1]-1)
    d2.setFullYear(array[0])
  

    var data2 = []; 

    if(d2.getDate() >= 28)
    {
      var day = d2.getDate();
      var month = d2.getMonth()+1;
      var year = d2.getFullYear();

      console.log(day);
      console.log(year);

      console.log('Validación de fechas manual');
      for (let index = 0; index < cantidadPagares; index++) 
      {
          month = month + 1;
          if(month == 13)
          {
            month= 1;
            year = year +1;
          }

          var dates = month+"/"+day+"/"+year;

          console.log(dates);

          var m = moment(dates, 'MM-DD-YYYY');
          console.log(m.isValid()); // false
          if(!m.isValid())
          {
            console.log('FECHA INVALIDA');

              var int_d = new Date(month+1+"/"+1+"/"+year);
              var d = new Date(int_d - 1);
              console.log('FECHA PROPUESTA');
              console.log(d);
              data2.push(d.toLocaleDateString());
          }
          else{
            var finalGood = new Date(dates);
            data2.push(finalGood.toLocaleDateString());
          }
      }
    }
    else
    {
      console.log('Entra al else');
      for (let index = 0; index < cantidadPagares; index++) 
      {
        // console.log(d2.getMonth() +1);
        d2.setMonth(d2.getMonth()+1);
        var dateString2 = d2.toLocaleDateString();
        console.log(dateString2);
        data2.push(dateString2 );
      }
    }

    console.log(data2);
    console.log('//Obtener pagos');

    return data2;
  }

  Imprimir(cliente, cantidad, cantidadLetra, cantidadPagares, cantidadtotal, totalLetra){

    console.log('CANTIDAD QUE SE VA IMPRIMIR:');
    console.log(cantidadtotal);
    let prueba2 = cantidadtotal.replaceAll('\.','')
    let prueba3 = parseInt(prueba2.replaceAll('\,',''))
    console.log(prueba3);



    const CantiddadTotalformatted = prueba3.toLocaleString("en", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    
    console.log(CantiddadTotalformatted);


    console.log('FINAL SE IMPRIMIO');
    console.log(cantidad)

    let cantidadAntes = cantidad
    // let cantidadAntes = parseInt(cantidad)

    var cantidadFormato = cantidadAntes.toLocaleString("en", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    
    console.log(cantidadFormato);
    if(!cantidadFormato.includes('$'))
    {
      cantidadFormato = '$'+cantidadFormato;
    }


    const fechaNow = this.obtenerFecha()
    const fechasarray = this.obtenerPagos(cantidadPagares)
    console.log('fechasarray');
    var vencimiento = fechasarray.length;
    console.log(vencimiento);
    var espacio = fechasarray[vencimiento-1]
    console.log(espacio);

    //Envio al servidor
    this.NuevoPrestamo(cliente, cantidadtotal, fechasarray, espacio, cantidad)

    const doc = new jsPDF();
    var espacio = 0;

    for (let step = 1; step <= cantidadPagares; step++) {
      if(step == 4)
      {
        doc.addPage();
        espacio = 0;
      }
      if(step == 7)
      {
        doc.addPage();
        espacio = 0;
      }
      if(step == 10)
      {
        doc.addPage();
        espacio = 0;
      }
      
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)
      doc.roundedRect(85, espacio+3, 20, 10, 3, 3, 'FD')
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)      
      doc.roundedRect(166, espacio+3, 40, 10, 3, 3, 'FD')
      doc.setFontSize(30)
      doc.text("PAGARÉ", 20, espacio+10);
      doc.setFontSize(14)
      doc.text("No.", 75, espacio+10);
      doc.text(step+"/"+cantidadPagares, 91, espacio+10);
      doc.text("BUENO POR:", 130, espacio+10);
      doc.text(cantidadFormato+".00", 170, espacio+10);
      doc.text("COLIMA, COLIMA A "+fechaNow, 100, espacio+19);
      doc.text("Debo(mos) y pagaré(mos) incondicionalmente por este Pagaré a la orden de BAKA", 10, espacio+24);
      doc.text("S DE R L DE C V En Colima, Colima el " + fechasarray[step-1], 10, espacio+30);
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)
      doc.roundedRect(10, espacio+33, 192, 12, 3, 3, 'FD')
      doc.text("("+cantidadLetra+" 00/100 M.N.)", 13, espacio+40);
      doc.setFontSize(7.5)
      doc.text("Valor Recibido a mi (nuestra) entera satisfacción. Este pagaré forma parte de una serie numerada del 1 al "+cantidadPagares+" y todos estan sujetos a las condición de que al no ", 10, espacio+50);
      doc.text("pagarse cualquiera de ellos a su vencimiento, seran exigibles todos los que le sigan en número, además de los ya vencidos, desde la fecha de vencimiento de ", 10, espacio+54);
      doc.text("este documento hasta el dia de su liquidación, cusará intereses moratorios al tipo de 3% mensual, pagadero en esta ciudad puntualmente con el principal.", 10, espacio+58);
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)
      doc.roundedRect(10, espacio+67, 100, 24, 3, 3, 'FD')
      doc.setFontSize(10)
      doc.text("DEUDOR", 85, espacio+65);
      doc.text("Nombre: " + cliente.name, 12, espacio+72);
      doc.text("Tel: " + cliente.tel, 12, espacio+77);
      

      
      if(cliente.direccion.length > 35)
      {
        var totalCadena = cliente.direccion.split(' ');
        console.log(totalCadena);
      
        var part = "";
        var part2 = "";
        var part3 = "";
        var Entra1 = true;
        var Entra2 = false;
        var Entra3 = false;

        var stepbug = 0;
        for (let step = 0; step < totalCadena.length; step++) 
        {
          stepbug = step;

            if(Entra1 === true && part.length + totalCadena[step].length < 35)
            {
                console.log('PART1');
                console.log(totalCadena[step]);
                part = part + totalCadena[step] + " ";
                continue;
            }
            else{
              Entra1 = false;
              Entra2 = true;
              console.log("================ESPACIO 1 LLENO=====================");
            }

          
            if(Entra2 === true && part2.length + totalCadena[step].length < 35 )
            { 
              console.log('PART2');
              console.log(totalCadena[step]);
              part2 = part2 + totalCadena[step] + " ";  
              continue;
            }
            else{
              Entra2 = false;
              Entra3 = true;
              console.log("================ESPACIO 2 LLENO=====================");
            }

            if(Entra3 === true && part3.length + totalCadena[step].length < 35 )
            {
              console.log('PART3');
              console.log(step);
              console.log(stepbug);
              console.log(totalCadena[step]);
              console.log('/ PART3');
              part3 = part3 + totalCadena[step] + " ";
              continue;
            }
            else{
              Entra3 = false;
              console.log("================ESPACIO 3 LLENO=====================");
            }
        }        

        doc.text("Dirección: "+ part , 12, espacio+81);
        doc.text(part2, 12, espacio+85);
        doc.text(part3, 12, espacio+89);

        console.log('CUT CADENA');
      }
      else
      {
        doc.text("Dirección: "+ cliente.direccion , 12, espacio+82);
      }

      // doc.text("Colonia: "+ cliente.colonia, 12, espacio+87);
      doc.setFontSize(10)
      doc.text("ACEPTO(AMOS)", 120, espacio+70);
      doc.text("FIRMA(S):", 120, espacio+87);
      doc.text("Población:", 12, espacio+2000);
      doc.setLineDash([10, 10], 0);
      doc.line(0, espacio+97, 500, espacio+97);
      doc.setLineDash(0, 0);

      espacio = espacio + 100;
    }

    if(espacio === 300)
    {
      espacio = 0;
      doc.addPage();
    }
    
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)
      doc.roundedRect(85, espacio+3, 20, 10, 3, 3, 'FD')
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)
      doc.roundedRect(166, espacio+3, 40, 10, 3, 3, 'FD')
      doc.setFontSize(30)
      doc.text("PAGARÉ", 20, espacio+10);
      doc.setFontSize(14)
      doc.text("No.", 75, espacio+10);
      doc.text("1/1", 91, espacio+10);
      doc.text("BUENO POR:", 130, espacio+10);
      doc.text(CantiddadTotalformatted+".00", 170, espacio+10);
      doc.text("COLIMA, COLIMA A "+fechaNow, 100, espacio+19);
      doc.text("Debo(mos) y pagaré(mos) incondicionalmente por este Pagaré a la orden de BAKA", 10, espacio+24);
      doc.text("S DE R L DE C V En Colima, Colima el " + fechasarray[cantidadPagares-1], 10, espacio+30);
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)
      doc.roundedRect(10, espacio+33, 192, 12, 3, 3, 'FD')
      doc.text("("+totalLetra+" 00/100 M.N.)", 13, espacio+40);
      doc.setFontSize(7.5)
      doc.text("Valor Recibido a mi (nuestra) entera satisfacción. Este pagaré forma parte de una serie numerada del 1 al 1 y todos estan sujetos a las condición de que al no ", 10, espacio+50);
      doc.text("pagarse cualquiera de ellos a su vencimiento, seran exigibles todos los que le sigan en número, además de los ya vencidos, desde la fecha de vencimiento de ", 10, espacio+54);
      doc.text("este documento hasta el dia de su liquidación, cusará intereses moratorios al tipo de 3% mensual, pagadero en esta ciudad puntualmente con el principal.", 10, espacio+58);
      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)  
      doc.roundedRect(10, espacio+67, 100, 24, 3, 3, 'FD')
      doc.setFontSize(10)
      doc.text("DEUDOR", 85, espacio+65);
      doc.text("Nombre: " + cliente.name, 12, espacio+72);
      doc.text("Tel: " + cliente.tel, 12, espacio+77);
      

      if(cliente.direccion.length > 35)
      {
        var totalCadena = cliente.direccion.split(' ');
        console.log(totalCadena);
      
        var part = "";
        var part2 = "";
        var part3 = "";
        var Entra1 = true;
        var Entra2 = false;
        var Entra3 = false;

        var stepbug = 0;
        for (let step = 0; step < totalCadena.length; step++) 
        {
          stepbug = step;

            if(Entra1 === true && part.length + totalCadena[step].length < 35)
            {
                console.log('PART1');
                console.log(totalCadena[step]);
                part = part + totalCadena[step] + " ";
                continue;
            }
            else{
              Entra1 = false;
              Entra2 = true;
              console.log("================ESPACIO 1 LLENO=====================");
            }

          
            if(Entra2 === true && part2.length + totalCadena[step].length < 35 )
            { 
              console.log('PART2');
              console.log(totalCadena[step]);
              part2 = part2 + totalCadena[step] + " ";  
              continue;
            }
            else{
              Entra2 = false;
              Entra3 = true;
              console.log("================ESPACIO 2 LLENO=====================");
            }

            if(Entra3 === true && part3.length + totalCadena[step].length < 35 )
            {
              console.log('PART3');
              console.log(step);
              console.log(stepbug);
              console.log(totalCadena[step]);
              console.log('/ PART3');
              part3 = part3 + totalCadena[step] + " ";
              continue;
            }
            else{
              Entra3 = false;
              console.log("================ESPACIO 3 LLENO=====================");
            }
        }        

        doc.text("Dirección: "+ part , 12, espacio+81);
        doc.text(part2, 12, espacio+85);
        doc.text(part3, 12, espacio+89);

        console.log('CUT CADENA');
      }
      else
      {
        doc.text("Dirección: "+ cliente.direccion , 12, espacio+82);
      }


      doc.text("ACEPTO(AMOS)", 120, espacio+70);
      doc.text("FIRMA(S):", 120, espacio+87);
      doc.text("Población:", 12, espacio+2000);
      doc.setLineDash([10, 10], 0);
      doc.line(0, espacio+97, 500, espacio+97);
      doc.setLineDash(0, 0);

    doc.save("a4.pdf");
  }


  ImprimirAvales(cantidadPagares, avalNombre1, avalDomicilio1, avalNombre2, avalDomicilio2){
    console.log("Imprimir avales");
    console.log(avalDomicilio1);

    const doc = new jsPDF();
    var espacio = 0;

    for (let step = 1; step <= cantidadPagares; step++) {
      if(step == 4)
      {
        doc.addPage();
        espacio = 0;
      }
      if(step == 7)
      {
        doc.addPage();
        espacio = 0;
      }
      if(step == 10)
      {
        doc.addPage();
        espacio = 0;
      }
      
      doc.text(70, espacio+15, 'Datos y firma(s) del(os) aval(es)')
      doc.text(10, espacio+25, 'Nombre: ' + avalNombre1)
      
      if(avalDomicilio1.length > 50)
      {
        console.log('ENTRA A CUT CADENA AVAL 1');
        var Part3 = "";
        var Part2 = "";
        const PartResult = avalDomicilio1.substring(0, 50);
        var hola = PartResult.lastIndexOf(" ")
        const Part1 = avalDomicilio1.substring(0, hola);
        var Part22 = avalDomicilio1.substring(hola+1);

        //---------------PART 3----------//
        if(Part22.length > 50)
        {
          
          const Part2Sub = Part22.substring(0, 50);
          var hola2 = Part2Sub.lastIndexOf(" ")
          console.log(hola2);

          Part2 = Part2Sub.substring(0, hola2);
          console.log('Part2');
          console.log(Part2);
          Part3 = Part22.substring(hola2+1);
          console.log('Part3');
          console.log(Part3);
        }
        else{
          Part2 = Part22;
        }

        doc.text(10, espacio+35, "Dirección: "+ Part1 )
        doc.text(Part2, 37, espacio+40);
        doc.text(Part3, 37, espacio+45);

      }
      else
      {
        doc.text(10, espacio+35, 'Dirección: ' + avalDomicilio1)
      }    
    
      doc.text(10, espacio+55, 'Firma:')
      doc.line(35, espacio+55, 200, espacio+55)


      /////////////////////////////////SEGUNDO AVAL///////////////////////////////
      doc.text(10, espacio+65, 'Nombre: ' + avalNombre2)

      if(avalDomicilio2.length > 50)
      {
        console.log('ENTRA A CUT CADENA AVAL 2');
        console.log('ENTRA A CUT CADENA AVAL 1');
        var Part3 = "";
        var Part2 = "";
        const PartResult = avalDomicilio2.substring(0, 50);
        var hola = PartResult.lastIndexOf(" ")
        const Part1 = avalDomicilio2.substring(0, hola);
        var Part22 = avalDomicilio2.substring(hola+1);
        // for (let step = 0; step < totalCadena.length; step++) 
        // {
        //     if(part1ConEspacio)
        //     {
        //       if((part.length + totalCadena[step].length) < 50 )
        //       {
        //         part = part + totalCadena[step] + " ";  
        //       }
        //       else{
        //         part1ConEspacio = false;
        //       }
        //     }
        //     else if(part2ConEspacio)
        //     {
        //       if((part2.length + totalCadena[step].length) < 50 )
        //       {    
        //         part2 = part2 + totalCadena[step] + " ";  
        //       }else{
        //         part2ConEspacio = false;
        //       }
        //     }
        //     else
        //     {
        //       part3 = part3 + totalCadena[step] + " ";
        //     }
        // }

        if(Part22.length > 50)
        {
          
          const Part2Sub = Part22.substring(0, 50);
          var hola2 = Part2Sub.lastIndexOf(" ")
          console.log(hola2);

          Part2 = Part2Sub.substring(0, hola2);
          console.log('Part2');
          console.log(Part2);
          Part3 = Part22.substring(hola2+1);
          console.log('Part3');
          console.log(Part3);
        }
        else{
          Part2 = Part22;
        }

        doc.text(10, espacio+75, "Dirección: "+ Part1 )
        doc.text(Part2, 37, espacio+80);
        doc.text(Part3, 37, espacio+85);


      }
      else
      {
        doc.text(10, espacio+75, 'Dirección: '+ avalDomicilio2)
      }   
      doc.text(10, espacio+95, 'Firma:')
      doc.line(35, espacio+95, 200, espacio+95)


      espacio = espacio + 100;
    }

    if(espacio === 300)
    {
      espacio = 0;
      doc.addPage();
    }
    
      doc.text(70, espacio+15, 'Datos y firma(s) del(os) aval(es)')
      doc.text(10, espacio+25, 'Nombre: ' + avalNombre1)



      if(avalDomicilio1.length > 50)
      {
        var totalCadena = avalDomicilio1.split(' ');
        var part = "";
        var part2 = "";
        var part3 = "";
        var part1ConEspacio = true
        var part2ConEspacio = true
        for (let step = 0; step < totalCadena.length; step++) 
        {
            if(part1ConEspacio)
            {
              if((part.length + totalCadena[step].length) < 50 )
              {
                part = part + totalCadena[step] + " ";  
              }
              else{
                part1ConEspacio = false;
              }
            }
            else if(part2ConEspacio)
            {
              if((part2.length + totalCadena[step].length) < 50 )
              {    
                part2 = part2 + totalCadena[step] + " ";  
              }else{
                part2ConEspacio = false;
              }
            }
            else
            {
              part3 = part3 + totalCadena[step] + " ";
            }
        }

        doc.text(10, espacio+35, "Dirección: "+ part )
        doc.text(part2, 37, espacio+40);
        doc.text(part3, 37, espacio+45);

      }
      else
      {
        doc.text(10, espacio+35, 'Dirección: ' + avalDomicilio1)
      }   

      doc.text(10, espacio+55, 'Firma:')
      doc.line(35, espacio+55, 200, espacio+55)




      doc.text(10, espacio+65, 'Nombre: ' + avalNombre2)

      if(avalDomicilio2.length > 50)
      {
        var totalCadena = avalDomicilio2.split(' ');
        var part = "";
        var part2 = "";
        var part3 = "";
        var part1ConEspacio = true
        var part2ConEspacio = true
        for (let step = 0; step < totalCadena.length; step++) 
        {
            if(part1ConEspacio)
            {
              if((part.length + totalCadena[step].length) < 50 )
              {
                part = part + totalCadena[step] + " ";  
              }
              else{
                part1ConEspacio = false;
              }
            }
            else if(part2ConEspacio)
            {
              if((part2.length + totalCadena[step].length) < 50 )
              {    
                part2 = part2 + totalCadena[step] + " ";  
              }else{
                part2ConEspacio = false;
              }
            }
            else
            {
              part3 = part3 + totalCadena[step] + " ";
            }
        }

        doc.text(10, espacio+75, "Dirección: "+ part )
        doc.text(part2, 37, espacio+80);
        doc.text(part3, 37, espacio+85);

      }
      else
      {
        doc.text(10, espacio+75, 'Dirección: ' + avalDomicilio2)
      }   
      doc.text(10, espacio+95, 'Firma:')
      doc.line(35, espacio+95, 200, espacio+95)


    doc.save("Avales.pdf");
  }

  DiseñoPagares(cliente, cantidad, cantidadLetra, cantidadPagares){

    console.log('DISEÑO DE PAGARE: ');
    console.log(cantidad);
    console.log('FINAL DISEÑO DE PAGARE: ');

    var prueba1 = "";
    for (let i= 0; i< cantidad.length; i++) {
      if(cantidad.charAt(i) !== ',')
      {
        if(cantidad.charAt(i) !== '.')
        {
          prueba1 = prueba1 + cantidad.charAt(i);
        }
        
      }
    }
    var cantidadJunta = prueba1;
    
    var cifra = parseInt(cantidadJunta) * .03;
    
    var cantidadPrueba = parseInt(cantidadJunta * .03).toLocaleString()
    cantidadPrueba = cantidadPrueba.replaceAll('.', ',');

    cantidad = cantidad.replaceAll('.', ',');

    numeros.numero2word().Config._setSingular("PESO");
    numeros.numero2word().Config._setPlural("PESOS");

    const letra = numeros.numero2word(cifra).toString().replace('MIL ', ' MIL ').toString().toUpperCase()
    var nueva = letra.replace('MIL LONES', 'MILLONES')
    var nueva2 = nueva.replace('MIL LON', 'MILLON')
    
    const cantidadLetradividida = nueva2
    

    

    this.Imprimir(cliente, cantidadPrueba , cantidadLetradividida, cantidadPagares, cantidad, cantidadLetra)
    
    if(!this.props.avales){
      this.ImprimirAvales(cantidadPagares, this.props.avalNombre1, this.props.avalDomicilio1, this.props.avalNombre2, this.props.avalDomicilio2)
    }
  }

  render() {

    // console.log('RENDER PRINT');
    // console.log(this.props.cantidad);
    // console.log(this.props.cantidadLetra);
    // console.log('RENDER PRINT');

    console.log(this.props.fechaPrestamo);
    

    if(this.props.cantidad === 'NaN')
    {
      return "";
    }
    if(this.props.cantidad === '')
    {
      return "";
    }

    
      return (
        <div>
          <button className="btn btn-primary" onClick={()=> this.DiseñoPagares(this.props.cliente, this.props.cantidad, this.props.cantidadLetra, this.props.cantidadPagares)}
            > Descargar </button>
        </div>
      );
  }
}


  export default Print