import React, { useEffect, useState } from "react";
import Sidebar from './Components/Sidebar/Sidebar'
import MaterialTable from "material-table";
import 'material-icons/iconfont/material-icons.css';
import { Link } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';
import NuevoPrestamo from '../pages/nuevoPrestamo'
import db from '../firebase-config'
import { collection, getDocs, addDoc,doc, updateDoc, deleteDoc  } from "firebase/firestore";

function Prestamos(){

  const [array,setArray] = useState([]);
  const [arrayLiquidado,setArrayLiquidado] = useState([]);
  const [respaldo,setRespaldo] = useState([]);

  function DeletePrestamo(updateRows){
    console.log('Entra al delete');
    console.log(updateRows);
    console.log(updateRows.id);
    
    const DeletesCliente = async() => {
  
      try {
        await deleteDoc(doc(db, "prestamos", updateRows.id));
        console.log("Document drop with ID: ", updateRows.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }     
    }
    DeletesCliente();
   }


   useEffect(() => {
    const obtenerDatos = async() => {
      const datos = await getDocs(collection(db,'prestamos'))
      console.log('TRAE DATOS: ');
      // console.log(respaldo);
      datos.forEach((documento) => {
        
        const temp = documento.data()
        console.log(documento.id);
        if(temp.activo)
        {
          
          var objects = {
            id: documento.id,
            Pagos: documento.data().Pagos,
            PagosStatus: documento.data().PagosStatus,
            activo: documento.data().activo,
            cantidadPrestada: documento.data().cantidadPrestada,
            cantidadinteres: documento.data().cantidadinteres,
            deudor: documento.data().deudor,
            fechaContrato: documento.data().fechaContrato,
            fechaLiquidacion: documento.data().fechaLiquidacion,
            interes: documento.data().interes
          };

          setArray(oldArray => [...oldArray,objects] );
          
          setRespaldo(oldArray => [...oldArray,documento.data()] );
          // setArray(oldArray => [...oldArray,temp] );
        }else{
          setArrayLiquidado(oldArray => [...oldArray,temp] );
        }

      });

    }
    obtenerDatos();
  }, []);

  console.log(array);
  console.log(respaldo);
  const columnas= [
    {
      title: 'Fecha de contrato',
      field: 'fechaContrato',
      type: 'date'
    },
    {
     title: 'Fecha de vencimiento',
     field: 'fechaLiquidacion',
     type: 'date'
   },
   {
     title: 'Cantidad prestada',
     field: 'cantidadPrestada',
     type: 'numeric'
   },
   {
     title: 'Porc. de Interes %',
     field: 'interes',
     type: 'numeric'
   },
   {
     title: 'Deudor',
     field: 'deudor'
   }
   ];

   const columnasLiquidado= [
    {
     title: 'Fecha de liquidación',
     field: 'fechaLiquidación',
     type: 'date'
   },
   {
     title: 'Cantidad prestada',
     field: 'cantidadPrestada',
     type: 'numeric'
   },
   {
     title: 'Porc. de Interes %',
     field: 'interes',
     type: 'numeric'
   },
   {
     title: 'Deudor',
     field: 'deudor'
   }
   ];
    return (
      <div className="wrapper">
      <nav>
        <Sidebar></Sidebar>
      </nav>
      <div className="marg">
      <h1>Administrar préstamos</h1>
      <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
      <Tab eventKey="nuevo" title="Nuevo Préstamo">
        <NuevoPrestamo />
      </Tab>
      <Tab eventKey="home" title="Préstamos Activos">
        <MaterialTable
          columns={columnas}
          data={array}
          title='Prestamos activos'
          options={{
            actionsColumnIndex: -1,
            exportAllData: true,
            columnsButton: true, 
            exportCsv: true, 
            exportButton: true
          }}
          localization={{
            header:{
              actions: 'Acciones'
            },
            body: { editRow: { deleteText: '¿Estás seguro de que quieres borrarlo?' } }
          }}
          editable={{
            onRowDelete:selectedRow => new Promise((resolve, reject) =>{
              const index = selectedRow.tableData.id;
              const updateRows = [...array]
              DeletePrestamo(selectedRow)
              updateRows.splice(index,1)
              setArray(updateRows)
              resolve()
            }),
          }}
          
        />
      </Tab>
      <Tab eventKey="profile" title="Préstamos liquidados">
      <MaterialTable
          columns={columnasLiquidado}
          data={arrayLiquidado}
          title='Prestamos liquidados'
          options={{
            actionsColumnIndex: -1,
            exportButton: true
          }}
          localization={{
            header:{
              actions: 'Acciones'
            },
            body: { editRow: { deleteText: '¿Estás seguro de que quieres borrarlo?' } }
          }}
        />
      </Tab>

    </Tabs>
      </div>
    </div>

    );
}

export default Prestamos;