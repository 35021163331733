import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import Menu from "../pages/Menu";
import Georeferen from "../pages/configuraciones/pages/Georeferenciación";
import ArticulosActivos from "../pages/configuraciones/pages/ArticulosActivos";
import Categorias from "../pages/configuraciones/pages/Categorias";
import ItemsTemp from "../pages/configuraciones/pages/ItemsTemp";
import ItemsPrueba from "../pages/configuraciones/pages/ItemsPrueba";
import MargenReal from "../pages/configuraciones/pages/MargenReal";
import PreciosCompetencia from "../pages/configuraciones/pages/PreciosCompetencia";
import AgrupacionTienda from "../pages/configuraciones/pages/AgrupacionTienda";
import ConcentradoCambios from "../pages/configuraciones/pages/ConcentradoCambios";
import KVI from "../pages/configuraciones/pages/KVI";

import Aprobacion from "../pages/Aprobacion";
import Ejecucion from "../pages/Ejecucion";
import Clientes from "../pages/clientes";
import Prestamos from '../pages/prestamos'
import NuevoPrestamo from '../pages/nuevoPrestamo'
import Pagos from '../pages/pagos'

function RoutesPage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/menu" element={<Menu />} />
        <Route exact path="/Clientes" element={<Clientes />} />
        <Route exact path="/Prestamos" element={<Prestamos />} />
        <Route exact path="/NuevoPrestamo" element={<NuevoPrestamo />} />
        <Route exact path="/Pagos" element={<Pagos />} />

        {/* <Route exact path="/Georeferenciacion" element={<Georeferen />} />
        <Route exact path="/KVI" element={<KVI />} />
        <Route exact path="/Categorias" element={<Categorias />} />
        <Route exact path="/ItemsTemp" element={<ItemsTemp />} />
        <Route exact path="/ItemsPrueba" element={<ItemsPrueba />} />
        <Route exact path="/MargenReal" element={<MargenReal />} /> */}
        {/* <Route
          exact
          path="/PreciosCompetencia"
          element={<PreciosCompetencia />}
        />
        <Route
          exact
          path="/AgrupacionDeTiendas"
          element={<AgrupacionTienda />}
        />
        <Route exact path="/ArticulosActivos" element={<ArticulosActivos />} />
        <Route
          exact
          path="/ConcentradoCambios"
          element={<ConcentradoCambios />}
        />
        <Route exact path="/Generar" element={<Generar />} />
        <Route exact path="/Consultas" element={<Consultas />} />
        <Route exact path="/Resultados" element={<Resultados />} />
        <Route exact path="/Aprobacion" element={<Aprobacion />} />
        <Route exact path="/Ejecucion" element={<Ejecucion />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesPage;
