import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Collapse } from "react-collapse";
import img from "../img/logonew.jpg";
import Cookies from "universal-cookie";
import "../css/Sidebar.css"
import { DropdownButton, Dropdown } from "react-bootstrap";
import logo from '../../../assets/img/Baka.png';
import  "../../../css/Dash.css"
import { BsCashCoin } from "react-icons/bs";
import { PiAddressBookBold } from "react-icons/pi";

function Sidebar() {
  // const [isOpened, setIsOpened] = useState(false);
  // const [isOpened2, setIsOpened2] = useState(false);
  const cookies = new Cookies();

  const cerrarSesion = () => {
    cookies.remove("id", { path: "/" });
    cookies.remove("apellido_paterno", { path: "/" });
    cookies.remove("apellido_materno", { path: "/" });
    cookies.remove("nombre", { path: "/" });
    cookies.remove("username", { path: "/" });
    window.location.href = "./";
  };

  return (
    // <div className="wrapper">
       <div class="container">
      <nav>
        <ul>
          <li>
            <a href="/menu">
              <img  src={logo} alt="Logo" width="200px" ></img>
            </a>
          </li>
          <br/>
          <br/>
          <li>
            <div>
            <Link to="/Clientes">
            <PiAddressBookBold /> 
            <label style= {{paddingLeft: 10}} >Clientes</label>
            </Link>
            </div>
          </li>
          <li> 
            <div>
              <Link to="/Prestamos">
              <BsCashCoin /> 
              <label style= {{paddingLeft: 10}} >Préstamos</label>
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Sidebar;
