import React, { Component, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import "../css/Dash.css"
import Test from "./Graficos/Test"
import { collection, getDoc, addDoc,doc, updateDoc, deleteDoc, query, getDocs   } from "firebase/firestore";
import db from '../firebase-config'
import moment from "moment";


// const cookies = new Cookies();

function Menu(){

  const [array,setArray] = useState([]); //Total de pagos
  const [visitasHoy,setvisitasHoy] = useState([]);
  const [loading,setLoading] = useState(false);
  
  // const date = new Date();

  useEffect(() => {
    const obtenerDatos = async() => {

      const datenow = moment().format("DD/MM/YYYY");
      console.log("datenow", datenow);
      const colRef = collection(db, "prestamos");
      const docsSnap = await getDocs(colRef);
      const list = []
      const listvisitas = []

      await docsSnap.forEach(doc => {
        list.push(doc.data())
        
        var pagos = doc.data().Pagos;
        
        pagos.forEach(element => {
          if(element == '2/1/2024'){
            console.log(doc.data());
            listvisitas.push(doc.data())
          }
          
        });
        setvisitasHoy(listvisitas)
      })

      setArray(list)
      console.log('Termina');
      console.log(listvisitas)
      setLoading(true);
  
        //   const temp = documento.data()

        //   if(temp.activo)
        //   {
        //     var objects = {
        //       id: documento.id,
        //       Pagos: documento.data().Pagos,
        //       PagosStatus: documento.data().PagosStatus,
        //       activo: documento.data().activo,
        //       cantidadPrestada: documento.data().cantidadPrestada,
        //       cantidadinteres: documento.data().cantidadinteres,
        //       deudor: documento.data().deudor,
        //       fechaContrato: documento.data().fechaContrato,
        //       fechaLiquidacion: documento.data().fechaLiquidacion,
        //       interes: documento.data().interes
        //     };

        //     setArray(oldArray => [...oldArray,objects] );
        //   }else{
        //     setArrayLiquidado(oldArray => [...oldArray,temp] );
        //   }
        // });
    }
    obtenerDatos();
    return () =>{
      console.log("FINAL Renderizado");
      setLoading(false);
    }
    
  }, []);

 
  
    return (
      
      <>
        <div className="wrapper">
        <nav>
            <Sidebar></Sidebar>
        </nav>
      
        <div className="container">
        {/* <section className="main">
          <div className="main-top">
            <h1>Directorio</h1>
            <i className="fas fa-user-cog"></i>
          </div>
          <div className="main-skills">
            <div className="card">
              <i className="fas fa-laptop-code"></i>
              <h3>Pagos para hoy</h3>
              <p>Join Over 1 million Students.</p>
              <button>Get Started</button>
            </div>
            <div className="card">
              <i className="fab fa-wordpress"></i>
              <h3>Visitas hoy</h3
              <button>Get Started</button>
            </div>
            <div className="card">
              <i className="fas fa-palette"></i>
              <h3>Visitas semana</h3>
              <p>Join Over 2 million Students.</p>
              <button>Get Started</button>
            </div>
            <div className="card">
              <i className="fab fa-app-store-ios"></i>
              <h3>Directorio</h3>
              <p>Join Over 1 million Students.</p>
              <button>Get Started</button>
            </div>
            <div className="card">
              <i className="fab fa-app-store-ios"></i>
              <h3>Directorio</h3>
              <p>Join Over 1 million Students.</p>
              <button>Get Started</button>
            </div>
          </div>
          <section className="main-course">
            <h1>Gráficos</h1>
            <div className="course-box">
              <ul>
                <li class="active">In progress</li>
                <li>explore</li>
                <li>incoming</li>
                <li>finished</li>
              </ul>
              <div className="course">
                <div className="box">
                  <h3>HTML</h3>
                  <p>80% - progress</p>
                  <button>continue</button>
                  <i className="fab fa-html5 html"></i>
                </div>
                <div className="box">
                  <h3>CSS</h3>
                  <p>50% - progress</p>
                  <button>continue</button>
                  <i className="fab fa-css3-alt css"></i>
                </div>
                <div className="box">
                  <Test />
                </div>
              </div>
            </div>
          </section>
        </section> */}
      
          </div>
    </div>
      </>
    );
  
}
export default Menu;